import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Formik, Form, Field, FieldArray } from "formik";
import {
  Dialog,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  DragIndicator as DragIcon,
} from "@material-ui/icons";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import * as Yup from "yup";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },
  formControl: {
    minWidth: 120,
    marginRight: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dragItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  divider: {
    margin: theme.spacing(3, 0),
  },
  progress: {
    margin: "auto",
    textAlign: "center"
  }
}));

const AutoFollowUpRuleSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  conditions: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required("Type is required"),
      operator: Yup.string().required("Operator is required"),
      value: Yup.string().required("Value is required"),
    })
  ),
  days: Yup.number()
    .typeError("Days must be a number")
    .min(1, "Days must be at least 1")
    .required("Days is required"),
  followUpMessage: Yup.string()
    .min(3, "Too Short! Minimum 3 characters")
    .required("Follow up message is required"),
  actions: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required("Type is required"),
      fromValue: Yup.string().required("From is required"),
      toValue: Yup.string().required("To is required"),
    })
  ),
});

const AutoFollowUpRuleModal = ({ open, onClose, autoFollowUpRuleId }) => {
  const classes = useStyles();

  const initialState = {
    name: "",
    conditions: [{ type: "labels", operator: "contains", value: "" }],
    days: 3,
    followUpMessage: "",
    actions: [],
  };

  const [rule, setRule] = useState(initialState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!autoFollowUpRuleId) {
      setRule(initialState);
      return;
    };

    setLoading(true);
    const debounce = setTimeout(() => {
      const autoFollowUpRule = async () => {
        try {
          const { data } = await api.get(
            `/auto-follow-up-rule/${autoFollowUpRuleId}`
          );
          const conditions = [];
          const actions = [];

          if (
            data?.autoFollowUpConditions &&
            data?.autoFollowUpConditions.length > 0
          ) {
            data?.autoFollowUpConditions.forEach((condition) => {
              conditions.push({
                id: condition?.id,
                type: condition?.type,
                operator: condition?.operator,
                value: condition?.value,
              });
            });
          }

          if (
            data?.autoFollowUpActions &&
            data?.autoFollowUpActions.length > 0
          ) {
            data?.autoFollowUpActions.forEach((action) => {
              actions.push({
                id: action?.id,
                type: action?.type,
                fromValue: action?.fromValue,
                toValue: action?.toValue,
              });
            });
          }

          setRule({
            name: data?.name,
            conditions,
            days: data?.days,
            followUpMessage: data?.message,
            actions,
          });
        } catch (error) {
          toastError(error);
        }

        setLoading(false);
      };

      autoFollowUpRule();
    }, 500);
    return () => clearTimeout(debounce);
  }, [autoFollowUpRuleId, open]);

  const handleSubmit = async (value) => {
    try {
      if (autoFollowUpRuleId) {
        await api.put(`/auto-follow-up-rule/${autoFollowUpRuleId}`, value);

        toast.success(i18n.t("AutoFollowUpRule.toasts.updated"));
      } else {
        await api.post("/auto-follow-up-rule", value);

        toast.success(i18n.t("AutoFollowUpRule.toasts.created"));
      }
      onClose();
    } catch (error) {
      toastError(error);
    }
  };

  const handleClose = () => {
    onClose();
    setRule(initialState);
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        onClose={() => onClose(false)}
      >
        <DialogTitle>
          {autoFollowUpRuleId
            ? `${i18n.t("autoFollowUpRuleModal.title.edit")}`
            : `${i18n.t("autoFollowUpRuleModal.title.add")}`}
        </DialogTitle>
        {autoFollowUpRuleId && loading ? (
          <DialogContent dividers>
            <div className={classes.progress}>
            <CircularProgress />
            </div>
          </DialogContent>
        ) : (
          <>
            <Formik
              initialValues={rule}
              enableReinitialize={true}
              validationSchema={AutoFollowUpRuleSchema}
              onSubmit={(values, actions) => {
                setTimeout(() => {
                  handleSubmit(values);
                  actions.setSubmitting(false);
                }, 400);
              }}
            >
              {({ touched, errors, isSubmitting, values }) => (
                <Form>
                  <DialogContent dividers>
                    <Field
                      as={TextField}
                      fullWidth
                      label={i18n.t("autoFollowUpRuleModal.form.name")}
                      autoFocus
                      name="name"
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                      variant="outlined"
                      margin="dense"
                      className={classes.textField}
                      required
                    />

                    <Field
                      as={TextField}
                      fullWidth
                      label={i18n.t("autoFollowUpRuleModal.form.days")}
                      autoFocus
                      name="days"
                      type="number"
                      error={touched.days && Boolean(errors.days)}
                      helperText={touched.days && errors.days}
                      variant="outlined"
                      margin="dense"
                      className={classes.textField}
                      required
                    />

                    <Field
                      as={TextField}
                      fullWidth
                      label={i18n.t(
                        "autoFollowUpRuleModal.form.followUpMessage"
                      )}
                      autoFocus
                      name="followUpMessage"
                      error={
                        touched.followUpMessage &&
                        Boolean(errors.followUpMessage)
                      }
                      helperText={
                        touched.followUpMessage && errors.followUpMessage
                      }
                      variant="outlined"
                      margin="dense"
                      className={classes.textField}
                      multiline
                      minRows={5}
                      required
                    />

                    <Divider className={classes.divider} />
                    <Typography variant="body1">
                      {i18n.t("autoFollowUpRuleModal.form.conditions.title")}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {i18n.t(
                        "autoFollowUpRuleModal.form.conditions.description"
                      )}
                    </Typography>
                    <FieldArray name="conditions">
                      {({ move, remove, push }) => (
                        <>
                          <DragDropContext
                            onDragEnd={({ source, destination }) => {
                              if (destination) {
                                move(source.index, destination.index);
                              }
                            }}
                          >
                            <Droppable droppableId="conditions">
                              {(provided) => (
                                <List
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {values.conditions.map((condition, index) => (
                                    <Draggable
                                      key={index}
                                      draggableId={`condition-${index}`}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <ListItem
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          className={classes.dragItem}
                                          style={{
                                            ...provided.draggableProps.style,
                                            backgroundColor: snapshot.isDragging
                                              ? "#f0f0f0"
                                              : "white",
                                            boxShadow: snapshot.isDragging
                                              ? "0 0 0 1px rgba(0,0,0,0.1)"
                                              : "none",
                                          }}
                                        >
                                          <IconButton
                                            {...provided.dragHandleProps}
                                            size="small"
                                          >
                                            <DragIcon />
                                          </IconButton>
                                          <Grid
                                            container
                                            spacing={2}
                                            alignItems="center"
                                          >
                                            <Grid item xs={12} sm={3}>
                                              <FormControl
                                                fullWidth
                                                className={classes.formControl}
                                              >
                                                <InputLabel>Type</InputLabel>
                                                <Field
                                                  as={Select}
                                                  name={`conditions.${index}.type`}
                                                >
                                                  <MenuItem value="labels">
                                                    Labels
                                                  </MenuItem>
                                                  <MenuItem value="lastMessageSender">
                                                    Last Message Sender
                                                  </MenuItem>
                                                  <MenuItem value="contactNumber">
                                                    Contact Number
                                                  </MenuItem>
                                                </Field>
                                              </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                              <FormControl
                                                fullWidth
                                                className={classes.formControl}
                                              >
                                                <InputLabel>
                                                  Operator
                                                </InputLabel>
                                                <Field
                                                  as={Select}
                                                  name={`conditions.${index}.operator`}
                                                >
                                                  <MenuItem value="contains">
                                                    Contains
                                                  </MenuItem>
                                                  <MenuItem value="equals">
                                                    Equals
                                                  </MenuItem>
                                                  <MenuItem value="notEquals">
                                                    Not Equals
                                                  </MenuItem>
                                                  <MenuItem value="greaterThan">
                                                    Greater Than
                                                  </MenuItem>
                                                  <MenuItem value="lessThan">
                                                    Less Than
                                                  </MenuItem>
                                                  <MenuItem value="greaterThanOrEqual">
                                                    Greater Than or Equal
                                                  </MenuItem>
                                                  <MenuItem value="lessThanOrEqual">
                                                    Less Than or Equal
                                                  </MenuItem>
                                                </Field>
                                              </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                              <Field
                                                as={TextField}
                                                type={values.conditions[index].type === "lastMessageSender" ? "datetime-local" : "text"}
                                                fullWidth
                                                label={i18n.t(
                                                  "autoFollowUpRuleModal.form.conditions.value"
                                                )}
                                                name={`conditions.${index}.value`}
                                                error={
                                                  touched.conditions?.[index]
                                                    ?.value &&
                                                  Boolean(
                                                    errors.conditions?.[index]
                                                      ?.value
                                                  )
                                                }
                                                helperText={
                                                  touched.conditions?.[index]
                                                    ?.value &&
                                                  errors.conditions?.[index]
                                                    ?.value
                                                }
                                                className={classes.textField}
                                              />
                                            </Grid>
                                            <Grid item xs={12} sm={2}>
                                              <IconButton
                                                onClick={() => remove(index)}
                                                size="small"
                                              >
                                                <DeleteIcon />
                                              </IconButton>
                                            </Grid>
                                          </Grid>
                                        </ListItem>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </List>
                              )}
                            </Droppable>
                          </DragDropContext>
                          <Button
                            startIcon={<AddIcon />}
                            onClick={() => {
                              push({
                                type: "labels",
                                operator: "equals",
                                value: "",
                              });
                            }}
                            variant="outlined"
                            color="primary"
                            className={classes.button}
                          >
                            {i18n.t(
                              "autoFollowUpRuleModal.form.conditions.add"
                            )}
                          </Button>
                        </>
                      )}
                    </FieldArray>

                    <Divider className={classes.divider} />
                    <Typography variant="body1">
                      {i18n.t("autoFollowUpRuleModal.form.actions.title")}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {i18n.t("autoFollowUpRuleModal.form.actions.description")}
                    </Typography>
                    <FieldArray
                      name="actions"
                      render={(arrayHelpers) => (
                        <>
                          {values.actions && values.actions.length > 0
                            ? values.actions.map((action, index) => (
                                <Grid
                                  container
                                  spacing={2}
                                  style={{ marginTop: 10 }}
                                  key={index}
                                  alignItems="center"
                                >
                                  <Grid item xs={12} sm={4}>
                                    <FormControl
                                      fullWidth
                                      className={classes.formControl}
                                    >
                                      <InputLabel>
                                        {i18n.t(
                                          "autoFollowUpRuleModal.form.actions.type"
                                        )}
                                      </InputLabel>
                                      <Field
                                        as={Select}
                                        name={`actions.${index}.type`}
                                      >
                                        <MenuItem value="updateLabels">
                                          Update Labels
                                        </MenuItem>
                                      </Field>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={4}>
                                    <Field
                                      as={TextField}
                                      fullWidth
                                      label={i18n.t(
                                        "autoFollowUpRuleModal.form.actions.from"
                                      )}
                                      name={`actions.${index}.fromValue`}
                                      error={
                                        touched.actions?.[index]?.from &&
                                        Boolean(errors.actions?.[index]?.from)
                                      }
                                      helperText={
                                        touched.actions?.[index]?.from &&
                                        errors.actions?.[index]?.from
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={3}>
                                    <Field
                                      as={TextField}
                                      fullWidth
                                      label={i18n.t(
                                        "autoFollowUpRuleModal.form.actions.to"
                                      )}
                                      name={`actions.${index}.toValue`}
                                      error={
                                        touched.actions?.[index]?.to &&
                                        Boolean(errors.actions?.[index]?.to)
                                      }
                                      helperText={
                                        touched.actions?.[index]?.to &&
                                        errors.actions?.[index]?.to
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={1}>
                                    <IconButton
                                      onClick={() => arrayHelpers.remove(index)}
                                      size="small"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              ))
                            : null}
                          <Button
                            startIcon={<AddIcon />}
                            onClick={() =>
                              arrayHelpers.push({
                                type: "updateLabels",
                                fromValue: "",
                                toValue: "",
                              })
                            }
                            variant="outlined"
                            color="primary"
                            className={classes.button}
                          >
                            {i18n.t("autoFollowUpRuleModal.form.actions.add")}
                          </Button>
                        </>
                      )}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      {i18n.t("cancel")}
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      {i18n.t("save")}
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default AutoFollowUpRuleModal;
